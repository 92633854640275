<template>
    <div class="recipes">
        <div class="funMedicine coverTitle" style="margin-top: 0;">
            <div class="coverTitleTop">
                <img src="https://image.giantgocloud.com/www//ImageMapping/image/20231101/0C159F4A4DD44D35A1F581EE94A4B1C9.png"
                    alt="">
            </div>
            <div class="coverTitle_title">
                <img src="https://image.giantgocloud.com/www//ImageMapping/image/20231130/BBC844DDDB7B4A298A54CAA12C1FD51F.png"
                    alt="">
            </div>
            <div class="coverTitle_content">
                <img src="https://image.giantgocloud.com/www//ImageMapping/image/20231101/33ACFC1BBF324E9AA89DB82D0FD8EFA1.png"
                    alt="">
            </div>
            <div class="messageinfo">
                <div>姓名 / Compellation：{{ data.Name }}</div>
                <div>性别 / Gender：{{ data.Sex }}</div>
                <div>年龄 / Age：{{ data.Age }}</div>
                <div>出生日期 / Date of birth：{{ data.Birth }}</div>
                <div>检测日期 / Test date：{{ data.CheckDate }}</div>
            </div>
            <div class="first_bottom">
                <div class="first_bottom1"><img
                        src="https://image.giantgocloud.com/www//ImageMapping/image/20231101/8060313FF8FB416E837B9C1BCA38DC9F.png"
                        alt=""></div>
                <div class="first_bottom2">© 2023 SCALLOP CLOUD | ealth Guardian</div>
            </div>
            <!-- <div style="position: absolute;left: 360px;bottom: 10px;;font-size: 12px;color: #1386B0">
                免疫健康分析（共{{ obj1.length + list4.length + 3 }}页）P1
            </div> -->
        </div>
        <div class="funMedicine funMedicinesecod">
            <div class="funMedicinesecod_line"></div>
            <div class="funMedicinesecodImg"><img
                    src="https://image.giantgocloud.com/www//ImageMapping/image/20231101/5229756316E64CABAF93EC50781B648C.png"
                    alt=""></div>
            <div class="funMedicinesecodContend">
                <div class="funMedicinesecodContend1">什么是免疫年龄？</div>
                <div class="funMedicinesecodContend2"></div>
                <div class="funMedicinesecodContend3" style="color: #4E4E4E;">
                    免疫年龄是一种衡量免疫系统功能衰老程度的新指标，反映个体免疫健康和抗病能力。免疫系统随年龄变化，导致感染和疾病风险增加。免疫年龄的关键标志是CD95-CD28 + T细胞数量，其线性下降与年龄高度相关。这一指标可用于评估个体的免疫状态，研究免疫衰老与疾病之间的关系。InsideTracker等技术平台通过人工智能算法计算免疫年龄，提供了客观的免疫功能评估工具，有望改进健康管理和疾病预防。免疫年龄为了更健康的老龄化提供了科学依据。
                </div>
            </div>
            <div style="position: absolute;right: 60px;bottom: 30px;font-size: 12px;color: #1386B0">
                免疫健康分析（共{{ obj1.length + newpageNum + 2 }}页）P1
            </div>
        </div>
        <div class="funMedicine3">
            <!-- <div class="funMedicine1Head">
                <div class="funMedicine1HeadLeft"></div>
                <div class="funMedicine1HeadTitle">上海华测医学检测所</div>
                <div class="funMedicine1HeadRight">
                    <div>The ultimate</div>
                    <div>indicator health analysis</div>
                </div>
            </div> -->
            <div class="funMedicinesecod_line"></div>

            <div class="immunity_age">
                <!-- <div class="immunity_ageLine"></div> -->
                <div class="immunity_ageContent">
                    <div class="englishName">YOUR PROJECT METRICS</div>
                    <div class="chinaName">免疫年龄</div>
                </div>
            </div>
            <!-- 免疫年龄 -->
            <div class="immunity_ageList">
                <div class="immunity_ageListName">
                    <div class="immunity_ageListTitleLeft">
                        <div class="immunity_dot"></div>
                        <div class="immunity_Name">免疫年龄</div>
                    </div>
                    <div class="immunity_ageListTitleRight">
                        <div class="immunity_ageListTitleRightContent" v-if="data.Age > data.TheAge">
                            <span>比实际年龄小</span><span class="age">{{ data.Age - data.TheAge }}岁</span>
                        </div>
                        <div class="immunity_ageListTitleRightContent" v-if="data.Age < data.TheAge">
                            <span>比实际年龄大</span><span class="age">{{ data.TheAge - data.Age }}岁</span>
                        </div>
                    </div>
                </div>
                <div class="immunity_age_legend">
                    <div class="immunity_age_legend1"><img src="../../assets/immune/baohu 1@2x.png" alt=""></div>
                    <div class="immunity_age_legend2"><img src="../../assets/immune/免@2x.png" alt=""></div>
                    <div class="immunity_age_legend3">{{ data.TheAge }}岁</div>
                </div>

            </div>
            <div class="imgLine"> <img src="../../assets/immune/Rectangle 346241063@2x.png" alt=""> </div>
            <!-- 免疫年龄等级 -->
            <div class="immunity_ageList">
                <div class="immunity_ageListName">
                    <div class="immunity_ageListTitleLeft">
                        <div class="immunity_dot"></div>
                        <div class="immunity_Name">免疫年龄等级</div>
                    </div>
                    <!-- <div class="immunity_ageListTitleRight">
                        <div class="immunity_ageListTitleRightContent"><span>比实际年龄小</span><span class="age">4.3岁</span></div>
                    </div> -->
                </div>

                <div class="immunity_age_legend">
                    <div class="immunity_age_legend1"><img src="https://image.giantgocloud.com/www//ImageMapping/image/20231109/D5D7BC80C3EB4E748C95A812D1940B10.png" alt=""></div>
                    <div class="immunity_age_legend2"><img src="../../assets/immune/免@2x.png" alt=""></div>
                    <div class="immunity_age_legend4" v-if="data.AgeLevel=='A'||data.AgeLevel=='A-'||data.AgeLevel=='A+'" style="color: #10854A;" >{{ data.AgeLevel }}</div>
                    <div class="immunity_age_legend4" v-if="data.AgeLevel=='B'||data.AgeLevel=='B-'||data.AgeLevel=='B+'" style="color: #5EC692;" >{{ data.AgeLevel }}</div>
                    <div class="immunity_age_legend4" v-if="data.AgeLevel=='C'||data.AgeLevel=='C-'||data.AgeLevel=='C+'" style="color: #EEC122;" >{{ data.AgeLevel }}</div>
                    <div class="immunity_age_legend4" v-if="data.AgeLevel=='D'||data.AgeLevel=='D-'||data.AgeLevel=='D+'" style="color: #D51931;" >{{ data.AgeLevel }}</div>
                </div>

            </div>
            <div style="position: absolute;right: 60px;bottom: 30px;font-size: 12px;color: #1386B0">
                免疫健康分析（共{{ obj1.length + newpageNum + 2 }}页）P2
            </div>
        </div>
        <!-- <div class="funMedicine4">
            <div class="funMedicinesecod_line"></div>
            <div class="funMedicine4Leaf"></div>
            <div class="funMedicine4List">
                <ul>
                    <li v-for="(item, index) in list2" :key="index">
                        <div class="funMedicine4ListNames">
                            <div class="funMedicine4ListHorizontal"></div>
                            <div class="funMedicine4ListNoun">{{ item.name }}</div>
                        </div>
                        <div class="funMedicine4ListText">
                            {{ item.text }}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="funMedicine4Middle"></div>
            <div class="funMedicine4Footer">免疫健康分析（共{{ obj1.length + newpageNum + 4 }}页）P4</div>
        </div> -->
        <div v-if="list3.length >= 10">
            <div class="funMedicine5">
                <div class="funMedicinesecod_line"></div>
                <!-- <Headers :headerData="data"></Headers> -->
                <div class="funMedicine5Cloumns"></div>
                <div class="funMedicine5Text">
                    <div class="funMedicine5TextTop">YOUR PROJECT METRICS</div>
                    <div class="funMedicine5TextBottom">您的项目指标</div>
                </div>
                <div class="table-wrapper">
                    <table>
                        <tr style="color: #fff;font-size: 16px;">
                            <th>项目</th>
                            <th>结果</th>
                            <th>

                            </th>
                            <th>状态</th>
                            <th>等级</th>
                        </tr>
                        <tr v-for="(item, index) in list5" :key="index">
                            <td class="IndicatorListCheckItem" style="width: 90px;">{{ item.CheckItem }}</td>
                            <td class="IndicatorListCheckItemValue">{{ item.CheckResultValue }}&nbsp;{{ item.CheckResultUnit }}</td>
                            <td class="processdata">
                                <div style="position: relative;">
                                    <div class="process">
                                        <div class="process1" v-for="(color, index) in item.ColorLengths" :key="index"
                                            :style="{
                                                width: `${(150 / item.ColorLengths.length).toFixed(1) + 'px'}`,
                                                background: `${color.ColorNum}`,
                                            }">
                                        </div>
                                    </div>
                                    <!-- transform: `translate(${-130 + (150 * Number(item.ColorValuePercent)) / 100
                    }px,-6px)` -->
                                    <div class="Yuan" :style="{
                                        border: `2px solid ${item.RoundColorNum}`, left: `${((item.ColorValuePercent / 100) * 150).toFixed(1) + 'px'}`, bottom: `-1px`
                                    }">
                                    </div>
                                </div>

                            </td>
                            <td class="IndicatorListCheckItemValue">{{ item.OtherDescribe }}</td>
                            <td class="IndicatorListCheckItemValue">{{ item.Level }}</td>
                        </tr>
                    </table>
                </div>
                <div style="position: absolute;right: 60px;bottom: 30px;font-size: 12px;color: #1386B0">
                免疫健康分析（共{{ obj1.length + newpageNum + 2 }}页）P3
            </div>
            </div>
            <div class="funMedicine5">
                <div class="funMedicinesecod_line"></div>
                <!-- <Headers :headerData="data"></Headers> -->
                <div class="funMedicine5Cloumns"></div>
                <div class="funMedicine5Text">
                    <div class="funMedicine5TextTop">YOUR PROJECT METRICS</div>
                    <div class="funMedicine5TextBottom">您的项目指标</div>
                </div>
                <div class="table-wrapper">
                    <table>
                        <tr style="color: #fff;font-size: 16px;" class="IndicatorListCheckItemA">
                            <th>项目</th>
                            <th>结果</th>
                            <th>
                            </th>
                            <th>状态</th>
                            <th>等级</th>
                        </tr>
                        <tr v-for="(item, index) in list6" :key="index">
                            <td class="IndicatorListCheckItem" style="width: 90px;">{{ item.CheckItem }}</td>
                            <td class="IndicatorListCheckItemValue">{{ item.CheckResultValue }}&nbsp;{{ item.CheckResultUnit }}</td>
                            <td class="processdata">
                                <div style="position: relative;">
                                    <div class="process">
                                        <div class="process1" v-for="(color, index) in item.ColorLengths" :key="index"
                                            :style="{
                                                width: `${(150 / item.ColorLengths.length).toFixed(1) + 'px'}`,
                                                background: `${color.ColorNum}`,
                                            }">
                                        </div>
                                    </div>
                                    <!-- transform: `translate(${-130 + (150 * Number(item.ColorValuePercent)) / 100
                    }px,-6px)` -->
                                    <div class="Yuan" :style="{
                                        border: `2px solid ${item.RoundColorNum}`, left: `${((item.ColorValuePercent / 100) * 150).toFixed(1) + 'px'}`, bottom: `-1px`
                                    }">
                                    </div>
                                </div>
                            </td>
                            <td class="IndicatorListCheckItemValue">{{ item.OtherDescribe }}</td>
                            <td class="IndicatorListCheckItemValue">{{ item.Level }}</td>
                        </tr>
                    </table>
                </div>

                <div style="position: absolute;right: 60px;bottom: 30px;font-size: 12px;color: #1386B0">
                免疫健康分析（共{{ obj1.length + newpageNum + 2 }}页）P4
            </div>
            </div>
        </div>
        <div v-if="list3.length < 10">
            <div class="funMedicine5">
                <div class="funMedicinesecod_line"></div>
                <!-- <Headers :headerData="data"></Headers> -->
                <!-- <div class="funMedicine5Cloumns"></div> -->
                <div class="funMedicine5Text">
                    <div class="funMedicine5TextTop">YOUR PROJECT METRICS</div>
                    <div class="funMedicine5TextBottom">您的项目指标</div>
                </div>
                <div class="table-wrapper">
                    <table>
                        <tr style="color: #fff;font-size: 16px;">
                            <th>项目</th>
                            <th>结果</th>
                            <th>

                            </th>
                            <th>状态</th>
                            <th>等级</th>
                        </tr>
                        <tr v-for="(item, index) in list3" :key="index">
                            <td class="IndicatorListCheckItem" style="width: 90px;">{{ item.CheckItem }}</td>
                            <td class="IndicatorListCheckItemValue">{{ item.CheckResultValue }}{{ item.CheckResultUnit }}</td>
                            <td class="processdata">
                                <div style="position: relative;">
                                    <div class="process">
                                        <div class="process1" v-for="(color, index) in item.ColorLengths" :key="index"
                                            :style="{
                                                width: `${(150 / item.ColorLengths.length).toFixed(1) + 'px'}`,
                                                background: `${color.ColorNum}`,
                                            }">
                                        </div>
                                    </div>
                                    <!-- transform: `translate(${-130 + (150 * Number(item.ColorValuePercent)) / 100
                    }px,-6px)` -->
                                    <div class="Yuan" :style="{
                                        border: `2px solid ${item.RoundColorNum}`, left: `${((item.ColorValuePercent / 100) * 150).toFixed(1) + 'px'}`, bottom: `-1px`
                                    }">
                                    </div>
                                </div>
                            </td>
                            <td class="IndicatorListCheckItemValue">{{ item.OtherDescribe }}</td>
                            <td class="IndicatorListCheckItemValue">{{ item.Level }}</td>
                        </tr>
                    </table>
                </div>
                <div style="position: absolute;right: 60px;bottom: 30px;font-size: 12px;color: #1386B0">
                免疫健康分析（共{{ obj1.length + newpageNum + 2 }}页）P4
            </div>
            </div>
        </div>
        <div class="funMedicineBox6" v-for="(item1, index1) in obj1" :key="index1">
            <div class="funMedicinesecod_line" style="position: absolute;top: 0;margin:40px 0"></div>
            <div class="funMedicine6" v-for="(item2, index2) in item1" :key="index2"
                :id="'getDivHeight' + String(index1) + String(index2)">

                <div class="funMedicine6_box" v-if="item2.CheckItem">
                    <div>
                        <div class="funMedicine6HeadTop">
                            <div class="funMedicine6Head" v-if="item2">
                                <span>您的{{ item2.CheckItem }}指标为：</span><span>{{ item2.CheckResultValue }}&nbsp;{{
                                    item2.CheckResultUnit
                                }}</span>
                            </div>
                            <!-- <div class="funMedicine6Cloumns"></div> -->

                            <div class="process" style="margin-top: 30px;" id="processdata">
                                <div class="process1" v-for="(color, index) in item2.ColorLengths" :key="index"
                                    :class="index == item2.ColorLengths.length - 1 ? 'process1_A' : ''" :style="{
                                        width: `${(180 / item2.ColorLengths.length).toFixed(1) + 'px'}`,
                                        background: `${color.ColorNum}`,
                                    }">
                                    <span>{{ color.ColorName }}</span>
                                </div>

                                <div class="Yuan" :style="{
                                    border: `2px solid ${item2.RoundColorNum}`, top: `-5px`, left: `${((item2.ColorValuePercent / 100) * 180).toFixed(1) + 'px'}`
                                }">
                                    <!-- <div style="position: relative;width: 100%;height: 100%;">
                                        <div class="funMedicine6Num" :style="{
                                            position: `relative`, top: `-50px`, left: `0`
                                        }">
                                            {{ item2.CheckResultValue }}&nbsp;&nbsp;{{ item2.CheckResultUnit }}
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div>
                                <div class="funMedicine6Text">
                                    {{ item2.CheckItem }}：
                                </div>
                                <div class="problem" v-for="(itemd, index) in item2.text" :key="index">
                                    <div class="problemText">
                                        {{ itemd }}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <div style="position: absolute;right: 60px;bottom: 30px;font-size: 12px;color: #1386B0">
               免疫健康分析（共{{ obj1.length + newpageNum + 2 }}页）P{{ index1 + 3 + newpageNum }}
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Headers from './componetPdf/component/headers.vue'
import { stringify } from 'qs'
import  H5Report  from '@/api/index'
export default {
    components: { Headers },
    data() {
        return {
            obj1: [],
            newlistDataA: {},
            // 数据列表对应上面的内容
            list: [
                {
                    name: '葡萄糖',
                    text: '最佳空腹血糖水平表明您的身体正在正常运作以处理葡萄糖并将其用作燃料.葡萄糖耐量会随着年龄的增长而降低，导致血糖升高。除了高水平的HbA1c外，高水平的空腹血糖是评估胰岛素敏感性或胰岛素抵抗的最佳测量方法。因此，免疫与空腹血糖的增加有关。'
                },
                {
                    name: '低密度脂蛋白（LDL）',
                    text: 'LDL通常被称为“坏”胆固醇，因为如果长时间升高，它会在您的血管中形成斑块，导致它们狭窄和收缩血流。数据显示，低密度脂蛋白往往随着年龄的增长而增加，这意味着较高的低密度脂蛋白与年龄较大有关。'
                },
                {
                    name: '甘油三酯',
                    text: '甘油三酯是食物和身体中脂肪的主要形式。血液中甘油三酯的水平可能表明可用的能量过多，或者可能意味着肝脏有问题，因为甘油三酯是在肝脏中产生的。研究还发现，较高的甘油三酯水平与患与年龄相关的疾病的可能性增加有关，因此较高的水平可以增加您的免疫年龄。'
                },
                {
                    name: '血红蛋白A1c（HbA1c）',
                    text: '该指标代表过去90-120天的平均血糖水平。葡萄糖在血液中积聚时会与血红蛋白结合，因此血液中的葡萄糖越高，HbA1c 就越高。高HbA1c水平可能表明胰岛素功能不正常，导致患糖尿病的风险。成熟的研究和客户数据都表明，葡萄糖往往会随着年龄的增长而增加，导致HbA1c升高，并增加患糖尿病等疾病的风险。'
                },
                {
                    name: 'GGT',
                    text: 'GGT是一种肝酶和肝脏健康的指标。GGT在肝脏如何分解毒素方面也起着至关重要的作用。高于优化的GGT水平可能表明疾病或肝脏损伤，导致疲劳，食欲不振，恶心和体重减轻。GGT往往随着年龄的增长而增加，而GGT水平升高会增加您的免疫年龄。'
                },
                {
                    name: '嗜碱性粒细胞',
                    text: '嗜碱性粒细胞是一种白细胞，在免疫系统中充当感染战士，通常在过敏反应期间被激活，但其他水平较低。优化的嗜碱性粒细胞水平表明炎症减少和整体健康状况更好。权威的数据发现，嗜碱性粒细胞百分比于年龄高度相关，这意味著较高的嗜碱性球百分比与年龄有关。'
                }
            ],
            list2: [
                {
                    name: 'SHBG',
                    text: '生激素结合球蛋白或SHBG是一种与性激素结合并运输性激索 (尤其是酮) 的蛋白质。高水平的SHBG会减可用的游离，活性睾酮的数量，因此优化SHBG可以增加性激素的可用性。这有益于骨骼健康、性功能和心脏建康，并保持较低的免疫年龄。'
                },
                {
                    name: '高敏C反应蛋白（hsCRP）',
                    text: 'isCRP是一种循环蛋白，是一般炎症的良好指标。男性炎症标志物 (包括hsCRP) 与年龄相关的增加已被研究充分记录，这种增加通常被称为“炎症”，这意味着较高的hsCRP与更快的免疫有关。较低的hsCRP与更好的心脏健康，更低的血压和改善的血糖控制有关。'
                },
                {
                    name: '中性粒细胞',
                    text: '中性粒细胞是最丰富的白细胞类型，也是免疫反应期间首先被激活的白细胞。优化的中性粒细胞与整体健康和阳性免疫反应有关，较高的中性粒细胞百分比与年龄较大高度相关。'
                },
                {
                    name: '平均红细胞血红蛋白（MCH）',
                    text: '这是红细胞中血红蛋白的平均量。高MCH水平会导致疲劳，脑雾，混乱或记忆丧失。男性的MCH往往随着年铃的增长而增加，这意味着较高的MCH水平与年龄较大有关。'
                },
                {
                    name: '平均红细胞血红蛋白浓度（MCHC）',
                    text: 'MCHC是红细胞中血红蛋白的平均浓度，它有助于将氧气输送到全身。高水平的母婴健康中心会导致疲劳、虚弱、体重减轻或腹痛。MCHC往往随着年龄的增长而增加，这意味着较高的MCHC水平可能会使您的免疫年龄增加年限。'
                }
            ],
            list3: [
                {
                    name: '胆固醇',
                    num: 120,
                    dan: 'mg/dl',
                    qita: '过高'
                },
                {
                    name: '胆固醇',
                    num: 120,
                    dan: 'mg/dl',
                    qita: '过高'
                },
                {
                    name: '胆固醇',
                    num: 120,
                    dan: 'mg/dl',
                    qita: '过高'
                },
                {
                    name: '胆固醇',
                    num: 120,
                    dan: 'mg/dl',
                    qita: '过高'
                },
                {
                    name: '胆固醇',
                    num: 120,
                    dan: 'mg/dl',
                    qita: '过高'
                },
                {
                    name: '胆固醇',
                    num: 120,
                    dan: 'mg/dl',
                    qita: '过高'
                },
                {
                    name: '胆固醇',
                    num: 120,
                    dan: 'mg/dl',
                    qita: '过高'
                },
                {
                    name: '胆固醇',
                    num: 120,
                    dan: 'mg/dl',
                    qita: '过高'
                }
            ],
            list4: [],
            list5: [],
            list6: [],
            data: {},
            dan: {},
            num: 0,
            text: [],
            newList: [],
            iLength: 0,
            pageNum: 0,
            newLength: 0,
            newpageNum: 0
        }
    },
    created() { },
    mounted() {
        let that = this
        const id = this.$query('id')
        axios
            .post(
                // 'https://healthingcoretest.giantgocloud.com/api/ScenceReport/GetMianyiReportIndex',
                //   'https://healthingcore365.giantgocloud.com/api/ScenceReport/GetMianyiReportIndex',
                  `${H5Report}/api/ReportData/GetMianyiReportIndex`,
                {
                    id: id
                }
            )
            .then((item) => {
                console.log(item,'item')
                this.data = item.data.data.reportUserInfo
                this.dan = item.data.data.sickTitleDetailReportDtos.filter((item) => {
                    return item.CheckItem == '白蛋白'
                })
                this.list3 = item.data.data.sickTitleDetailReportDtos.map((data) => {
                    data.text = data.CheckItemDetail.split(';')
                    let inners
                    if (data.OtherDescribe == '最佳') {
                        inners =
                            '最佳: 您当下的指标属于(最佳范围)非常棒👍 继续保持健康生活方式'
                    } else if (data.OtherDescribe == '正常') {
                        inners = '正常: 您当下的指标属于(健康范围) 请继续保持健康生活方式'
                    } else if (data.OtherDescribe == '注意') {
                        inners =
                            '注意: 您当下指标属于(关注阶段) 请关注您的健康，积极干预，保持健康生活方式'
                    } else if (data.OtherDescribe == '风险') {
                        inners =
                            '风险: 您当下的指标属于(风险阶段) 请重视您的健康，及时干预，调整生活方式，远离疾病'
                    } else if (data.OtherDescribe == '过低') {
                        inners =
                            '过低: 您当下指标属于(疾病阶段)请及时干预治疗，调整生活方式，"恢复健康'
                    } else if (data.OtherDescribe == '过高') {
                        inners =
                            '过高: 您当下指标属于(疾病阶段)请及时干预治疗，调整生活方式，"恢复健康'
                    }
                    data.inners = inners
                    return data

                })
                console.log(this.list3.length, 'this.list3.length')
                if (this.list3.length >= 10) {
                    this.list4 = this.group(this.list3, 9)
                    this.list5 = this.list4[0]
                    this.list6 = this.list4[1]
                    console.log(this.list4,'this.list4')
                }
                that.setnewdata()

            })

    },
    methods: {
        group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    },
        // 函数自调用
        setnewdata() {
            let that = this
            if (that.iLength < that.list3.length) {
                for (let q = 0; q < that.pageNum + 1; q++) {
                    if (!that.obj1[q]) {
                        that.obj1[q] = []
                    }
                }
                console.log(that.pageNum, 'that.pageNum')
                //将数据插入新的二维数组中
                that.obj1[that.pageNum].push(that.list3[that.iLength])
                this.$forceUpdate()
                //获取dom节点判断dom节点距离左边距的距离
                setTimeout(() => {
                    let getDivHeightBox = document.getElementById('getDivHeight' + String(that.pageNum) + String(that.obj1[that.pageNum].length - 1))
                    if (getDivHeightBox.offsetLeft > 500) {
                        that.obj1[that.pageNum] = that.obj1[that.pageNum].slice(0, -1);
                        that.pageNum++;
                        that.iLength--
                        that.iLength++
                        that.setnewdata()
                    } else {
                        //当上面的判断距离在右边出现第三行时，创建一个新的空数组并超出时截取插入下一个数组中
                        that.obj1[that.pageNum].push({})
                        this.$forceUpdate()
                        setTimeout(() => {
                            console.log('that.obj1', that.obj1)
                            let getDivHeightBox = document.getElementById('getDivHeight' + String(that.pageNum) + String(that.obj1[that.pageNum].length - 1))
                            console.log('getDivHeightBox' + that.iLength, 'getDivHeight' + String(that.pageNum) + String(that.obj1[that.pageNum].length - 1))
                            if (getDivHeightBox.offsetLeft > 500) {
                                that.obj1[that.pageNum] = that.obj1[that.pageNum].slice(0, -2);
                                that.pageNum++;
                                that.iLength = that.iLength - 2
                            } else {
                                that.obj1[that.pageNum] = that.obj1[that.pageNum].slice(0, -1);
                            }
                            that.iLength++
                            that.setnewdata()
                        }, 1);
                    }

                }, 1);
            } else {
                console.log('666', this.obj1)
                console.log('777', this.obj1[this.obj1.length - 1][this.obj1[this.obj1.length - 1].length - 1])
                if (!this.obj1[this.obj1.length - 1][this.obj1[this.obj1.length - 1].length - 1].CheckItem) {
                    console.log(this.obj1[this.obj1.length - 1], '88888888888')
                    this.obj1[this.obj1.length - 1].slice(0, -1)
                }
            }
            if (this.list3.length > 10) {
                this.newpageNum = 2
            } else {
                this.newpageNum = 1
            }
        }
    }
}
</script>
<style lang='scss' scoped>
#processdata .process1:last-child {
    // width: 100px;
    border-radius: 0px 50px 50px 0px !important;
    height: 5px;
    background: #e29a30;
    opacity: 1;
}

.IndicatorListCheckItemA {
    font-size: 16px;
}
.IndicatorListCheckItemValue{
    font-size: 10px;
}
.IndicatorListCheckItem {
    font-size: 10px;
}

.IndicatorListCheckstatus {
    font-size: 12px;
}

.IndicatorListCheckgrade {
    font-size: 12px;
}

.funMedicinesecod_line {
    width: 500px;
    height: 1px;
    background: #4E4E4E;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 25px;
}

.recipes {
    width: 595px;

    .processdata {
        width: 150px;

        .process {
            position: relative;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 150px;


            // margin: 30px 0;
            span {
                display: inline-block;
                transform: translateY(10px);
                margin-top: 5px;
            }

            .process1 {
                // width: 100px;
                height: 5px;
                background: #5ec692;
                opacity: 1;
            }

            .process1:nth-of-type(1) {
                // width: 100px;
                height: 5px;
                background: #d51931;
                opacity: 1;
                border-radius: 50px 0px 0px 50px;
            }

            .process1:nth-last-of-type(1) {
                // width: 100px;
                border-radius: 0px 50px 50px 0px !important;
                height: 5px;
                // background: #e29a30;
                opacity: 1;
            }
        }

        .Yuan {
            position: absolute;
            display: inline-block;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: white;
        }
    }

    .funMedicine {
        width: 595px;
        position: relative;
        height: 842px;
        box-sizing: border-box;
        overflow: hidden;

        .page {
            position: absolute;
            width: 40px;
            height: 40px;
            background: #1386b0;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            right: 0px;
            bottom: -10px;
            background: url("../../assets/analysis/be9299d8ce7aecbcc66619a0dd3f403.png") no-repeat;
            background-size: contain;
            text-align: center;
            font-size: 13px;
            padding-left: 2px;
            box-sizing: border-box;
            font-family: DIN-Bold, DIN;
            font-weight: bold;
            color: #ffffff;
            line-height: 33px;
        }
    }

    .coverTitle {
        margin-top: 64px;

        .coverTitleTop {
            width: 76.06px;
            height: 74.22px;
            margin: 62px auto 0 auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .coverTitle_title {
            width: 359px;
            height: 142.35px;
            margin: 58px auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .coverTitle_content {
            width: 498.56px;
            height: 127.56px;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .messageinfo {
            width: 498.56px;
            margin: 24px auto 0 auto !important;

            div {
                margin: 7px;
                width: 100%;
                height: 28px;
                background: #EFEFEF;
                opacity: 1;
                line-height: 28px;
                text-align: center;
            }
        }

        .first_bottom {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 90px;

            .first_bottom1 {
                width: 42.98px;
                height: 5.45px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .first_bottom2 {
                font-size: 8px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #9FA0A0;
                line-height: 9px;
            }
        }


    }

    .funMedicinesecod {


        .funMedicinesecodImg {
            width: 537px;
            height: 420px;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .funMedicinesecodContend {
            width: 500px;
            margin: 0 auto;

            .funMedicinesecodContend1 {
                font-weight: 600;
                color: #000;
                font-size: 12px;
            }

            .funMedicinesecodContend2 {
                color: #4E4E4E;
                font-size: 12px;
                margin: 16px 0;
            }

            .funMedicinesecodContend2 {
                color: #4E4E4E;
                font-size: 12px;
                margin: 16px 0;
            }
        }
    }

    .funMedicine3 {
        position: relative;
        height: 842px;
        overflow: hidden;

        .funMedicine5Cloumns {
            display: inline-block;
            width: 4px;
            height: 54px;
            margin-top: 64px;
            margin-left: 45px;
            // background: linear-gradient(117deg,
            //         #1386b0 0%,
            //         rgba(19, 134, 176, 0) 100%);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
        }

        .funMedicine5Footer {
            position: absolute;
            right: 20px;
            bottom: 20px;
            text-align: center;
            padding-left: 2px;
            box-sizing: border-box;
            font-weight: bold;
            color: #ffffff;
            line-height: 33px;
            height: 17px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #1386b0;
        }

        .funMedicine5Text {
            margin-bottom: 30px;
            margin-left: 8px;
            display: inline-block;

            .funMedicine5TextTop {
                width: 306px;
                height: 45px;
                font-size: 24px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #1386b0;
                line-height: 26px;
                -webkit-background-clip: text;
            }

            .funMedicine5TextBottom {
                margin-top: 8px;
                width: 162px;
                height: 26px;
                font-size: 24px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #4e4e4e;
                line-height: 26px;
                letter-spacing: 1px;
                -webkit-background-clip: text;
            }
        }

        .table-wrapper {
            overflow: hidden;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            width: 509px;
            height: 657px;
            margin: auto;
            overflow-x: scroll;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4e4e4e;
            line-height: 26px;
            -webkit-background-clip: text;
        }

        table {
            text-align: center;
            width: 509px;
            border-collapse: collapse;

            tr {
                width: 509px;
                height: 47px;
            }

            tr:nth-child(even) {
                background: rgb(232, 246, 250);
                opacity: 1;
            }

            tr:nth-child(1) {
                background: linear-gradient(90deg, #19a1d2 0%, #25d1dc 100%);
                opacity: 1;
            }
        }

        .funMedicine1Head {
            width: 500px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin: 0 auto;

            .funMedicine1HeadLeft {
                width: 68px;
                height: 17px;
                background: url("../../assets/immune/titleLeft.png") no-repeat;
                background-size: cover;
            }

            .funMedicine1HeadTitle {
                color: #1386B0;
                font-size: 18px;
                font-weight: 600;
            }

            .funMedicine1HeadRight {
                width: 153px;
                height: 22px;
                word-wrap: normal;
                text-align: right;
                color: #1386B0;
                font-size: 10px;
            }
        }

        .funMedicine3_Line {
            width: 500px;
            height: 2px;
            background: linear-gradient(180deg, #1386B0 0%, rgba(19, 134, 176, 0) 100%);
            margin: 20px auto 20px auto;
        }

        .immunity_age {
            display: flex;
            margin-left: 48px;

            // .immunity_ageLine {
            //     width: 4px;
            //     height: 51px;
            //     background: linear-gradient(117deg, #1386B0 0%, rgba(19, 134, 176, 0) 100%);
            // }

            .immunity_ageContent {
                font-size: 24px;
                text-align: left;
                margin-left: 10px;

                .englishName {
                    color: #1386B0;
                }

                .chinahName {
                    color: #4E4E4E
                }
            }
        }

        .imgLine {
            width: 518px;
            height: 1px;
            margin: 38px auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .immunity_ageList {
            width: 500px;
            margin: 38px auto;

            .immunity_ageListName {
                padding: 0 10px;
                display: flex;
                justify-content: space-between;

                .immunity_ageListTitleLeft {
                    display: flex;
                    align-items: center;

                    .immunity_dot {
                        width: 7px;
                        height: 7px;
                        background: #1386B0;
                        border-radius: 50%;
                    }

                    .immunity_Name {
                        font-size: 16px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #4E4E4E;
                        margin-left: 5px;
                    }
                }

                .immunity_ageListTitleRight {
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #4E4E4E;

                    .age {
                        color: #1386B0;
                    }
                }
            }

            .immunity_age_legend {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 29px;

                .immunity_age_legend1 {
                    width: 68px;
                    height: 68px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .immunity_age_legend2 {
                    width: 240px;
                    height: 44px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .immunity_age_legend3 {
                    font-size: 36px;
                    color: #5EC692;
                }
                .immunity_age_legend4{
                  font-size: 36px;
                }
            }
        }

        .funMedicine3Cloumns {
            display: inline-block;
            width: 4px;
            height: 54px;
            margin-top: 64px;
            margin-left: 45px;
            background: linear-gradient(117deg,
                    #1386b0 0%,
                    rgba(19, 134, 176, 0) 100%);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
        }

        .funMedicine3Text {
            display: inline-block;
            width: 279px;
            height: 60px;
            margin-left: 8px;
            margin-top: 59px;
            font-size: 24px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #1386b0;
            line-height: 30px;
            letter-spacing: 1px;
            -webkit-background-clip: text;
        }

        .funMedicine3Leaf {
            vertical-align: top;
            width: 144.43px;
            height: 135.38px;
            margin-left: 73px;
            margin-top: 54px;
            display: inline-block;
            background: url("../../assets/analysis/Vector@2x.png") no-repeat;
            background-size: cover;
        }

        .funMedicine3Inner {
            position: absolute;
            width: 505px;
            height: 38px;
            margin-top: 21px;
            margin-left: 45px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4e4e4e;
            line-height: 20px;
            -webkit-background-clip: text;
            top: 125px;
        }

        .funMedicine3List {
            margin-top: 15px;
            width: 570px;
            height: 557px;
            background: rgba(19, 134, 176, 0.1);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            margin-left: auto;

            ul {
                padding: 15px 38px 0px 46px;
                box-sizing: border-box;

                li {
                    margin-top: 14px;

                    .funMedicine3ListNames {
                        display: flex;
                        align-items: center;

                        .funMedicine3ListHorizontal {
                            width: 20px;
                            height: 2px;
                            background: #1386b0;
                            border-radius: 0px 0px 0px 0px;
                            opacity: 1;
                        }

                        .funMedicine3ListNoun {
                            height: 26px;
                            font-size: 14px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #1386b0;
                            line-height: 26px;
                            margin-left: 4px;
                            -webkit-background-clip: text;
                        }
                    }

                    .funMedicine3ListText {
                        width: 579px;
                        font-size: 12px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #4e4e4e;
                        line-height: 16px;
                        -webkit-background-clip: text;
                        transform: scale(0.9);
                        transform-origin: left;
                    }
                }
            }

            li:nth-child(1) {
                margin-top: 0px;
            }
        }

        .funMedicine3Footer {
            position: absolute;
            right: 20px;
            bottom: 20px;
            text-align: center;
            padding-left: 2px;
            box-sizing: border-box;
            font-weight: bold;
            color: #ffffff;
            line-height: 33px;
            height: 17px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #1386b0;
        }
    }

    .funMedicine1 {
        height: 842px;
        overflow: hidden;

        .funMedicine1Head {
            display: flex;
            justify-content: space-between;

            .funMedicine1HeadLeft {
                width: 167px;
                height: 109px;
                background: url("../../assets/analysis/1673738818.png") no-repeat;
                background-size: cover;
            }

            .funMedicine1HeadRight {
                width: 114px;
                height: 59px;
                margin-right: 34px;
                margin-top: 45px;
                background: url("https://image.giantgocloud.com/www//ImageMapping/image/20230901/25AB6190364946B9B033F7F08F8E58D1.png") no-repeat;
                background-size: contain;
            }
        }

        .HealthAnalysis {
            margin-top: 6px;
            width: 492px;
            height: 112px;
            margin-left: 70px;
            background: url("../../assets/analysis/Group\ 1171277411.png") no-repeat;
            background-size: contain;
        }

        .HealthBulle {
            width: 100%;
            height: 132px;
            margin-top: 169px;
            background: #1386b0;
        }

        .HealthBulleImg {
            position: absolute;
            top: 291px;
            width: 495px;
            height: 330px;
            margin-left: 50px;
            background: url("../../assets/analysis/Group\ 1171277401.png") no-repeat;
            background-size: cover;
        }

        .HealthInfo {
            // font-weight: 300;
            margin: 142px 50px 33px 50px;
            display: flex;
            justify-content: space-between;
            text-align: right;

            .HealthInfoText {
                width: 125px;
                height: 29px;
                font-size: 20px;
                font-family: Source Han Serif CN-Medium, Source Han Serif CN;
                font-weight: 300;
                color: #333333;
                line-height: 23px;
                letter-spacing: 1px;
                -webkit-background-clip: text;
            }
        }

        .HealthInfo2 {
            margin: 0px 50px;
            display: flex;

            // font-weight: 300;
            .HealthInfoText {
                width: 50%;
                height: 29px;
                font-size: 20px;
                font-family: Source Han Serif CN-Medium, Source Han Serif CN;
                font-weight: 300;
                color: #333333;
                line-height: 23px;
                letter-spacing: 1px;
                -webkit-background-clip: text;
                white-space: nowrap;
                text-align: right;
            }

            .HealthInfoText:nth-child(1) {
                text-align: left;
            }
        }

        .footer {
            background: rebeccapurple;
            margin-top: 56px;
            float: right;
            display: flex;

            .footerLeft {
                width: 90px;
                height: 15px;
                background: #1286b0;
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
            }

            .footerRight {
                width: 90px;
                height: 15px;
                background: #162b87;
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
            }
        }
    }

    .funMedicine2 {
        height: 842px;
        overflow: hidden;

        .HealthIndicatorAnalysis {
            margin-top: 55px;
            margin-left: 67px;
            width: 201px;
            height: 118px;
            font-size: 48px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #1386b0;
            line-height: 59px;
            letter-spacing: 2px;
            -webkit-background-clip: text;
        }

        .HealthIndicatorAnalysisText1 {
            width: 482px;
            height: 49px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4e4e4e;
            line-height: 26px;
            -webkit-background-clip: text;
            margin-top: 25px;
            margin-left: 67px;
        }

        .HealthIndicatorAnalysisText2 {
            margin-top: 14px;
            margin-left: 67px;
            width: 482px;
            height: 132px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4e4e4e;
            line-height: 26px;
            -webkit-background-clip: text;
        }

        .funMedicine2Img {
            margin-top: 28px;
            width: 595px;
            height: 252px;
            background: url("../../assets/analysis/Group1171276841@2x.png") no-repeat;
            background-size: cover;
        }

        .funMedicine2Img2 {
            margin-top: 28px;
            width: 595px;
            height: 252px;
            background: url("../../assets/analysis/567f23bb67d32a26e3109691a1063fa.png") no-repeat;
            background-size: cover;
        }

        .funMedicine2ImgBack {
            width: 595px;
            height: 121px;
            background: rgba(19, 134, 176, 0.2);
        }
    }

    .funMedicine3 {
        position: relative;
        height: 842px;
        overflow: hidden;

        .funMedicine3Cloumns {
            display: inline-block;
            width: 4px;
            height: 54px;
            margin-top: 60px;
            margin-left: 45px;
            // background: linear-gradient(
            //   117deg,
            //   #1386b0 0%,
            //   rgba(19, 134, 176, 0) 100%
            // );
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
        }

        .funMedicine3Text {
            display: inline-block;
            width: 279px;
            height: 60px;
            margin-left: 8px;
            // margin-top: 59px;
            font-size: 24px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #1386b0;
            line-height: 30px;
            letter-spacing: 1px;
            -webkit-background-clip: text;
        }

        .funMedicine3Leaf {
            vertical-align: top;
            width: 144.43px;
            height: 135.38px;
            margin-left: 73px;
            margin-top: 54px;
            display: inline-block;
            // background: url("../../assets/analysis/Vector@2x.png") no-repeat;
            background-size: cover;
        }

        .funMedicine3Inner {
            // position: absolute;
            width: 505px;
            height: 38px;
            // margin-top: 40px;
            margin-left: 45px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4e4e4e;
            line-height: 20px;
            -webkit-background-clip: text;
            // top: 125px;
        }

        .funMedicine3List {
            margin-top: 15px;
            // width: 570px;
            height: 557px;
            // background: rgba(19, 134, 176, 0.1);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            margin-left: auto;

            ul {
                padding: 15px 38px 0px 46px;
                box-sizing: border-box;

                li {
                    margin-top: 14px;

                    .funMedicine3ListNames {
                        display: flex;
                        align-items: center;

                        .funMedicine3ListHorizontal {
                            width: 20px;
                            height: 2px;
                            background: #1386b0;
                            border-radius: 0px 0px 0px 0px;
                            opacity: 1;
                        }

                        .funMedicine3ListNoun {
                            height: 26px;
                            font-size: 14px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #1386b0;
                            line-height: 26px;
                            margin-left: 4px;
                            -webkit-background-clip: text;
                        }
                    }

                    .funMedicine3ListText {
                        width: 579px;
                        font-size: 12px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #4e4e4e;
                        line-height: 16px;
                        -webkit-background-clip: text;
                        transform: scale(0.9);
                        transform-origin: left;
                    }
                }
            }

            li:nth-child(1) {
                margin-top: 0px;
            }
        }

        .funMedicine3Footer {
            position: absolute;
            right: 20px;
            bottom: 20px;
            text-align: center;
            padding-left: 2px;
            box-sizing: border-box;
            font-weight: bold;
            color: #ffffff;
            line-height: 33px;
            height: 17px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #1386b0;
        }
    }

    .funMedicine4 {
        position: relative;
        height: 842px;
        overflow: hidden;

        .funMedicine4Leaf {
            vertical-align: top;
            width: 144.43px;
            height: 135.38px;
            margin-left: 409px;
            margin-top: 54px;
            display: inline-block;
            // background: url("../../assets/analysis/Vector@2x.png") no-repeat;
            background-size: cover;
        }

        .funMedicine4List {
            position: absolute;
            top: 110px;
            width: 570px;
            height: 557px;
            margin-left: auto;

            ul {
                padding: 0px 38px 0px 38px;
                box-sizing: border-box;

                li {
                    margin-top: 14px;

                    .funMedicine4ListNames {
                        display: flex;
                        align-items: center;

                        .funMedicine4ListHorizontal {
                            width: 20px;
                            height: 2px;
                            background: #1386b0;
                            border-radius: 0px 0px 0px 0px;
                            opacity: 1;
                        }

                        .funMedicine4ListNoun {
                            height: 26px;
                            font-size: 14px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #1386b0;
                            line-height: 26px;
                            margin-left: 4px;
                            -webkit-background-clip: text;
                        }
                    }

                    .funMedicine4ListText {
                        width: 579px;
                        font-size: 12px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #4e4e4e;
                        line-height: 16px;
                        -webkit-background-clip: text;
                        transform: scale(0.9);
                        transform-origin: left;
                    }
                }
            }

            li:nth-child(1) {
                margin-top: 0px;
            }
        }

        .funMedicine4Middle {
            width: 409px;
            height: 197px;
            margin-top: 359px;
            margin-right: auto;
            // background: rgba(19, 134, 176, 0.1);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
        }

        .funMedicine4Footer {
            position: absolute;
            right: 20px;
            bottom: 20px;
            text-align: center;
            padding-left: 2px;
            box-sizing: border-box;
            font-weight: bold;
            color: #ffffff;
            line-height: 33px;
            height: 17px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #1386b0;
        }
    }

    .funMedicine5 {
        position: relative;
        height: 842px;
        overflow: hidden;

        .funMedicine5Cloumns {
            display: inline-block;
            width: 4px;
            height: 54px;
            margin-top: 40px;
            margin-left: 45px;
            // background: linear-gradient(117deg,
            //         #1386b0 0%,
            //         rgba(19, 134, 176, 0) 100%);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
        }

        .funMedicine5Footer {
            position: absolute;
            right: 20px;
            bottom: 20px;
            text-align: center;
            padding-left: 2px;
            box-sizing: border-box;
            font-weight: bold;
            color: #ffffff;
            line-height: 33px;
            height: 17px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #1386b0;
        }

        .funMedicine5Text {
            margin-bottom: 50px;
            margin-left: 8px;
            display: inline-block;

            .funMedicine5TextTop {
               white-space:nowrap;
                height: 26px;
                font-size: 24px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #1386b0;
                line-height: 26px;
                -webkit-background-clip: text;
            }

            .funMedicine5TextBottom {
                margin-top: 8px;
                width: 162px;
                height: 26px;
                font-size: 24px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #4e4e4e;
                line-height: 26px;
                letter-spacing: 1px;
                -webkit-background-clip: text;
            }
        }

        .table-wrapper {
            overflow: hidden;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            width: 509px;
            height: 657px;
            margin: auto;
            overflow-x: scroll;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4e4e4e;
            line-height: 26px;
            -webkit-background-clip: text;
        }

        table {
            text-align: center;
            width: 509px;
            border-collapse: collapse;

            tr {
                width: 509px;
                height: 47px;
            }

            tr:nth-child(even) {
                background: rgb(232, 246, 250);
                opacity: 1;
            }

            tr:nth-child(1) {
                background: linear-gradient(90deg, #19a1d2 0%, #25d1dc 100%);
                opacity: 1;
            }
        }
    }

    .funMedicineBox6 {
        display:inline-block;
        position: relative;
        height: 842px;
        // overflow: hidden;
        // background-repeat: no-repeat;
        // background-size: 100% 50%;
        // background-image: url("../../../assets/analysis/74bb1640c32d38c6ca82b00dadac7be.png"),
        //     url("../../../assets/analysis/370f08fe4ae8c4b519af7ae7e27b32c.png");
        // background-position: top, bottom;
        // display: flex;
        // padding: 30px;
        column-count: 2;
        column-fill: auto;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 80px 60px 0 60px;

        .funMedicine6 {
            position: relative;
            // height: 842px;
            overflow: hidden;
            // padding: 0 0 0 30px;

            .funMedicine6_box {
                display:inline-block;
                // width: 229px;
                position: relative;

                .process {
                    position: relative;
                    display: flex;
                    // justify-content: center;
                    text-align: center;
                    margin-top: 30px;

                    span {
                        display: inline-block;
                        transform: translateY(10px);
                        margin-top: 5px;
                    }

                    .process1 {
                        // width: 100px;
                        height: 4px;
                        background: #5ec692;
                        opacity: 1;
                    }

                    .process1:nth-of-type(1) {
                        height: 4px;
                        background: #d51931;
                        opacity: 1;
                        border-radius: 50px 0px 0px 50px;
                    }

                    .process1:nth-last-of-type(1) {
                        height: 4px;
                        background: #e29a30;
                        opacity: 1;
                        border-radius: 0px 50px 50px 0px !important;
                    }

                    .process1_A {
                        height: 4px;
                        background: #e29a30;
                        opacity: 1;
                        border-radius: 0px 50px 50px 0px !important;
                    }
                }

                // margin: 20px 0;
            }

            /* 设置两张图片的位置 */
            .funMedicine6HeadTop {
                // -webkit-column-break-inside: avoid;
                // margin-top: 10px;
            }

            .funMedicine6Head {
                margin-top: 5px;
                // margin-left: 44px;
                // width: 367px;
                height: 26px;
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #4e4e4e;
                line-height: 26px;

                // letter-spacing: 1px;
                span {
                    color: #1386b0;
                }
            }


            .funMedicine6Footer {
                position: absolute;
                width: 40px;
                height: 40px;
                background: #1386b0;
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                right: 0px;
                bottom: -10px;
                // background: url("../../../assets/analysis/be9299d8ce7aecbcc66619a0dd3f403.png") no-repeat;
                background-size: contain;
                text-align: center;
                font-size: 13px;
                padding-left: 2px;
                box-sizing: border-box;
                font-family: DIN-Bold, DIN;
                font-weight: bold;
                color: #ffffff;
                line-height: 33px;
            }

            .funMedicine6Cloumns {
                // margin-top: 12px;
                // margin-left: 44px;
                width: 122px;
                height: 4px;
                background: linear-gradient(117deg, #1386B0 0%, rgba(19, 134, 176, 0) 100%);
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                // margin: 10px 0;
            }

            .funMedicine6Num {
                width: 57px;
                height: 20px;
                font-size: 12px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #4e4e4e;
                line-height: 24px;
                margin-top: 20px;
                -webkit-background-clip: text;
            }


            .Yuan {
                position: absolute;
                display: inline-block;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background: white;
            }

            .processText {
                width: 528px;
                height: 26px;
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #4e4e4e;
                line-height: 26px;
                margin-top: 33px;
                margin-left: 44px;

                span {
                    color: #1386b0;
                }
            }

            .funMedicine6Cloumn {
                display: inline-block;
                width: 4px;
                height: 30px;
                margin-right: 8px;
                margin-left: 45px;
                margin-top: 51px;
                vertical-align: middle;
                background: linear-gradient(117deg,
                        #1386b0 0%,
                        rgba(19, 134, 176, 0) 100%);
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
            }

            .funMedicine6Text {
                margin-top: 39px;
                display: inline-block;
                width: 229px;
                // height: 30px;
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #1386b0;
                // line-height: 30px;
                letter-spacing: 1px;
                // -webkit-background-clip: text;
                // vertical-align: middle;
            }

            .problem {
                width: 229px;
                // margin-left: 45px;
                font-size: 10px;
                // margin-top: 8px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                // line-height: 14px;

                .problemText {
                    color: #4e4e4e;
                    // padding: 10px 0;

                    span {
                        color: #1386b0;
                        margin-right: 3px;
                    }
                }
            }

            .problem:nth-child(1) {
                // margin-top: 24px;
            }

            .footers {
                margin-top: 12px;
                margin-left: 45px;
                color: #1386b0;
            }
        }
    }

}
</style>
